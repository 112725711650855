<template>
    <v-container fluid>
        <v-tabs v-model="tab">
            <v-tab href="#tab-nrb"> {{$t('modules_name.income_generation')}}</v-tab>
            <v-tab-item value="tab-nrb">
                <NrbReportTable v-if="tab==='tab-nrb'"></NrbReportTable>
            </v-tab-item>
        </v-tabs>
    </v-container>
</template>

<script>
export default {
        name: "HotelReportTable",
        props: [
            'moduleName'
        ],
        components: {
            NrbReportTable: () => import(/* webpackChunkName: "AirDetailReportTable" */ './NrbReportTable'),
        },
        computed: {
            reportIsMonthWise() {
                return this.serverParams.ReportType === "monthwise";
            }
        },
        data() {
            return {
                tab: 'tab-nrb',
            };
        },
        methods: {},
        mounted() {

        }
}
</script>

<style scoped lang="scss">
</style>




